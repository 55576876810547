//COLORS throughtout the App
export const COLORS = {
  textLight: "white",
  textDark: "black",
  textGray: "gray",
  header: "#6194dd",
  card: "#6194dd",
  banner: "#6194dd",
  footer: "#0a192f",
  button: "#6531BD",
  primary: "#92bcea",
  secondary: "#171A21",
  warning: "#f57c00",
  error: "#d32f2f",
  success: "#66bb6a",
  shadow: "rgba(0, 0, 0, 0.5)",
  shadowError: "rgba(0, 0, 0, 0.7)",
};

//STEP Unit
export const STEP = 5;

//HEIGHTS for Components
export const HEIGHTS = {
  xxxs: `${STEP * 6}px`,
  xxs: `${STEP * 8}px`,
  xs: `${STEP * 10}px`,
  sm: `${STEP * 20}px`,
  md: `${STEP * 30}px`,
  lg: `${STEP * 40}px`,
  xl: `${STEP * 50}px`,
  xxl: `${STEP * 60}px`,
};

//SPACES for Margins, Paddings, Flex Gaps
export const SPACES = {
  xxxs: `${STEP * 2}px`,
  xxs: `${STEP * 3}px`,
  xs: `${STEP * 4}px`,
  sm: `${STEP * 5}px`,
  md: `${STEP * 6}px`,
  lg: `${STEP * 7}px`,
  xl: `${STEP * 8}px`,
  xxl: `${STEP * 9}px`,
  xxxl: `${STEP * 10}px`,
};

//SIZES for Radius, Icons, Images
export const SIZES = {
  xxs: `${STEP * 3}px`,
  xs: `${STEP * 4}px`,
  sm: `${STEP * 5}px`,
  md: `${STEP * 6}px`,
  lg: `${STEP * 7}px`,
  xl: `${STEP * 8}px`,
  xxl: `${STEP * 9}px`,
  xxxl: `${STEP * 10}px`,
};

//SIZES for Fonts
export const FONTS = {
  xxxs: `${STEP * 3}px`,
  xxs: `${STEP * 4}px`,
  xs: `${STEP * 6}px`,
  sm: `${STEP * 10}px`,
  md: `${STEP * 15}px`,
  lg: `${STEP * 25}px`,
  xl: `${STEP * 30}px`,
};

//SIZES for Borders
export const borderSizes = {
  xs: `1px`,
  sm: `2px`,
  lg: `3px`,
};
