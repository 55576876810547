import React from "react";
import { LogoStyled } from "./Logo.styles";

export interface LogoProps {
  onClick?: (event: React.MouseEvent) => void;
  logoImageUrl?: string;
  logoMP4?: string;
  logoWEBM?: string;
  logoMOV?: string;
  logoTitle: string;
  h1LogoImgLink?: string;
}

export const Logo: React.FC<LogoProps> = ({
  onClick,
  logoImageUrl,
  logoMP4,
  logoWEBM,
  logoMOV,
  logoTitle,
  h1LogoImgLink,
}) => (
  <LogoStyled onClick={onClick}>
    <img src={logoImageUrl} alt="" />
    <a href={h1LogoImgLink} target="_blank" rel="noopener noreferrer">
      <video className="leftVideo" autoPlay loop muted playsInline>
        <source src={logoMP4} type="video/mp4"></source>
        <source src={logoWEBM} type="video/webm"></source>
        <source src={logoMOV} type="video/mov"></source>
      </video>
    </a>
    <h1>{logoTitle}</h1>
    <a href={h1LogoImgLink} target="_blank" rel="noopener noreferrer">
      <video className="rightVideo" autoPlay loop muted playsInline>
        <source src={logoMP4} type="video/mp4"></source>
        <source src={logoWEBM} type="video/webm"></source>
        <source src={logoMOV} type="video/mov"></source>
      </video>
    </a>
  </LogoStyled>
);
