import styled from "@emotion/styled";
// import LogoIcon from "../src/assets/TWALogo.webp";
import LogoIcon from "../src/assets/TWALogo.png";
import ButtonIconImg from "../src/assets/SearchIcon.png";
import BGVideoPoster from "../src/assets/BGVideoPoster.jpg";
import BGVideoWEBM from "../src/assets/BGVideo.webm";
import BGVideoMOV from "../src/assets/BGVideo.mov";
import BGVideoMP4 from "../src/assets/BGVideo.mp4";
import LogoMP4 from "../src/assets/Logo.mp4";
import LogoMOV from "../src/assets/Logo.mov";
import LogoWEBM from "../src/assets/Logo.webm";
import { Global, css } from "@emotion/react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { COLORS, FONTS, SPACES, STEP } from "./theme";
import { Header } from "./stories/UI_Components/Header/Header";
import { Logo } from "./stories/UI_Components/Logo/Logo";
import { InputBox } from "./stories/UI_Components/InputBox/InputBox";
import { ButtonIcon } from "./stories/UI_Components/Button/Button";
import { CurrentWeather } from "./Func_Components/CurrentWeather/CurrentWeather";
import { ForecastWeather } from "./Func_Components/ForecastWeather/ForecastWeather";
import { Footer } from "./stories/UI_Components/Footer/Footer";

//Global styles
const GlobalStyles = css`
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }

  body {
    background: black;
  }

  /* Hide scrollbar */
  body::-webkit-scrollbar {
    display: none;
  }

  h2 {
    color: ${COLORS.textLight};
    text-transform: uppercase;
  }
`;

//App styles here
const StyledApp = styled.div`
  .weatherAppWrapper {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bgVideo {
    z-index: -1;
  }

  .overlayVideo {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(1px);
  }

  .bgVideo video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .weatherAppWrapper a {
    text-decoration: none;
    color: inherit;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    align-items: center;
    justify-content: center;
    gap: ${SPACES.xxs};
    margin-top: ${SPACES.xs};
    margin-bottom: ${SPACES.xxxs};
    color: ${COLORS.textDark};
  }

  .contentWrapper .intro h1 {
    font-size: ${FONTS.sm};
  }

  .contentWrapper .intro h2 {
    font-size: ${FONTS.xs};
    text-transform: none;
  }

  .error {
    border-radius: ${STEP}px;
    background: ${COLORS.shadowError};
    color: ${COLORS.error};
    font-size: ${STEP + 15}px;
    font-weight: 500;
    padding: ${STEP}px ${STEP + 15}px;
    text-align: center;
  }

  .hidden {
    visibility: hidden;
  }

  .intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${SPACES.sm};
    color: ${COLORS.textLight};
  }

  .searchBox {
    display: flex;
    justify-content: center;
    padding-top: ${SPACES.sm};
  }

  .forecastBtnWrapper,
  .currentBtnWrapper {
    margin-top: ${SPACES.xxs};
    text-align: center;
  }
  .currentBtnWrapper {
    margin-bottom: ${SPACES.xxs};
  }

  .forecastContentWrapper {
    display: flex;
    justify-content: space-evenly;
    gap: ${SPACES.xs};
    flex-wrap: wrap;
  }

  /* Media Queries for various Devices Responsiveness */
  /* Media Query below 950px */
  @media all and (max-width: 950px) {
    .contentWrapper .intro h1 {
      font-size: ${FONTS.xs};
    }
    .contentWrapper .intro h2 {
      font-size: ${FONTS.xxs};
    }

    .intro {
      gap: ${SPACES.xxxs};
      color: ${COLORS.textLight};
    }

    .searchBox {
      padding-top: ${SPACES.xxxs};
    }

    .error {
      font-size: ${FONTS.xxxs};
    }
  }
`;

export function App() {
  const [search, setSearch] = useState("");
  const [cityName, setCityName] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [searchErrorMessage, setSearchErrorMessage] = useState(false);

  useEffect(() => {
    // When user presses refresh on browser, if on /forecast to redirect back to / (home) on first and only useEffect run
    var currentPathname = window.location.pathname;
    if (currentPathname.includes("/forecast")) {
      var newPathname = currentPathname.replace("/forecast", "/");
      window.location.replace(newPathname);
    }
  }, []);

  function handleClickButton() {
    if (search === "") {
      setSearchErrorMessage(true);
    } else {
      setInitialLoad(false);
      setCityName(search);
      setSearch("");
      setSearchErrorMessage(false);
    }
  }

  function handleEnterPress(e: { key: string }) {
    if (e.key === "Enter") {
      if (search === "") {
        setSearchErrorMessage(true);
      } else {
        setInitialLoad(false);
        setCityName(search);
        setSearch("");
        setSearchErrorMessage(false);
      }
    }
  }

  function handleClickLogo() {
    setInitialLoad(true);
    setCityName("");
    setSearch("");
    setSearchErrorMessage(false);
  }

  return (
    <StyledApp>
      <Global styles={GlobalStyles} />
      <div className="weatherAppWrapper">
        <div className="bgVideo">
          <div className="overlayVideo"></div>
          <video poster={BGVideoPoster} autoPlay loop muted playsInline>
            <source src={BGVideoWEBM} type="video/webm"></source>
            <source src={BGVideoMOV} type="video/mov"></source>
            <source src={BGVideoMP4} type="video/mp4"></source>
          </video>
        </div>
        <BrowserRouter>
          {/* Header Component + Logo Component */}
          <Header
            leftContent={
              <Link to="/">
                <Logo
                  onClick={handleClickLogo}
                  logoImageUrl={LogoIcon}
                  logoTitle={"The Weather App"}
                />
              </Link>
            }
          />
          <div className="contentWrapper">
            {initialLoad ? (
              <div>
                <div className="intro">
                  <h1 className="question">How's the weather outside?</h1>
                  <h2>
                    <em>Enter your city and find out.</em>
                  </h2>
                  <div
                    className="searchBox"
                    tabIndex={0}
                    onKeyDown={handleEnterPress}
                  >
                    {/* InputBox Component */}
                    <InputBox
                      placeholder="City name"
                      name="search"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    ></InputBox>
                    {/* Button Component Icon */}
                    <ButtonIcon
                      onClick={handleClickButton}
                      btnIconUrl={ButtonIconImg}
                    />
                  </div>
                  {searchErrorMessage ? (
                    <div className="error">Please enter city name!</div>
                  ) : (
                    <div className="error hidden">Please enter city name!</div>
                  )}
                </div>
              </div>
            ) : (
              <Routes>
                <Route
                  path="/"
                  element={
                    <CurrentWeather
                      cityName={cityName}
                      initialLoad={initialLoad}
                      setInitialLoad={setInitialLoad}
                    />
                  }
                />
                <Route
                  path="forecast"
                  element={<ForecastWeather cityName={cityName} />}
                />
              </Routes>
            )}
          </div>
        </BrowserRouter>
        {/* Footer Component */}
        <Footer
          centerContent={
            <Logo
              logoTitle={"Copyright © 2022 Andrea Rizzo"}
              h1LogoImgLink="https://www.andrearizzo.com.mt/"
              logoMP4={LogoMP4}
              logoWEBM={LogoWEBM}
              logoMOV={LogoMOV}
            />
          }
        />
      </div>
    </StyledApp>
  );
}

export default App;
