import { WeatherCardGroupStyled } from "./WeatherCardGroup.styles";
import { WeatherInfoProps } from "../WeatherInfo/WeatherInfo";
import { CardStyled } from "../Card/Card.styles";
import { KeyValueCardStyled } from "../KeyValueCard/KeyValueCard.styles";
import { WeatherInfoStyled } from "../WeatherInfo/WeatherInfo.styles";

export interface WeatherCardGroupProps {
  weatherCards: Array<{
    weatherInfo: WeatherInfoProps;
    uvIndex?: number;
    humidity?: number;
    windSpeed?: number;
    windDegrees?: string;
    dewPoint?: number;
    pressure?: number;
    tempMax?: number;
    tempMin?: number;
  }>;
}

export const WeatherCardGroup: React.FC<WeatherCardGroupProps> = (props) => {
  return (
    <WeatherCardGroupStyled>
      {props.weatherCards.map((weatherCard, index) => (
        <CardStyled key={index}>
          <WeatherInfoStyled
            cityName={weatherCard.weatherInfo.cityName}
            countryName={weatherCard.weatherInfo.countryName}
            weatherDate={weatherCard.weatherInfo.weatherDate}
            weatherIconUrl={weatherCard.weatherInfo.weatherIconUrl}
            temp={weatherCard.weatherInfo.temp}
            tempUnit={weatherCard.weatherInfo.tempUnit}
            description={weatherCard.weatherInfo.description}
          >
            <h2>
              {weatherCard.weatherInfo.cityName},{" "}
              {weatherCard.weatherInfo.countryName}
            </h2>
            <h4>{weatherCard.weatherInfo.weatherDate?.toDateString()}</h4>
            <div className="imgWrapper">
              <img src={weatherCard.weatherInfo.weatherIconUrl} alt="" />
            </div>
            <h1>
              {weatherCard.weatherInfo.temp}
              <span>{weatherCard.weatherInfo.tempUnit}</span>
            </h1>
            <h3>{weatherCard.weatherInfo.description}</h3>
          </WeatherInfoStyled>
          <div className="bottomGroup">
            <div className="sideBySide">
              <KeyValueCardStyled
                keyName="UV"
                value={weatherCard.uvIndex}
                orientation="horizontal"
              >
                <h1>UV Index</h1>
                <h2>{weatherCard.uvIndex}%</h2>
              </KeyValueCardStyled>
              <KeyValueCardStyled
                keyName="Humidity"
                value={weatherCard.humidity}
                orientation="horizontal"
              >
                <h1>Humidity</h1>
                <h2>{weatherCard.humidity}%</h2>
              </KeyValueCardStyled>
            </div>
            <div className="sideBySide">
              <KeyValueCardStyled
                keyName="Wind Speed"
                value={weatherCard.windSpeed}
                orientation="horizontal"
              >
                <h1>Wind Spd</h1>
                <h2>{weatherCard.windSpeed}m/s</h2>
              </KeyValueCardStyled>
              <KeyValueCardStyled
                keyName="Direction"
                value={weatherCard.windDegrees}
                orientation="horizontal"
              >
                <h1>Wind Dir</h1>
                <h2>{weatherCard.windDegrees}</h2>
              </KeyValueCardStyled>
            </div>
            <div className="sideBySide">
              <KeyValueCardStyled
                keyName="Dew point"
                value={weatherCard.dewPoint}
                orientation="horizontal"
              >
                <h1>Dew point</h1>
                <h2>{weatherCard.dewPoint}°C</h2>
              </KeyValueCardStyled>
              <KeyValueCardStyled
                keyName="Pressure"
                value={weatherCard.pressure}
                orientation="horizontal"
              >
                <h1>Pressure</h1>
                <h2>{weatherCard.pressure}hPA</h2>
              </KeyValueCardStyled>
            </div>
            <div className="sideBySide">
              <KeyValueCardStyled
                keyName="Highest "
                value={weatherCard.tempMax}
                orientation="horizontal"
              >
                <h1>Highest</h1>
                <h2>{weatherCard.tempMax}°C</h2>
              </KeyValueCardStyled>

              <KeyValueCardStyled
                keyName="Lowest"
                value={weatherCard.tempMax}
                orientation="horizontal"
              >
                <h1>Lowest</h1>
                <h2>{weatherCard.tempMin}°C</h2>
              </KeyValueCardStyled>
            </div>
          </div>
        </CardStyled>
      ))}
    </WeatherCardGroupStyled>
  );
};
