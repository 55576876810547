import styled from "@emotion/styled";
import { COLORS, FONTS, HEIGHTS, SPACES } from "../../../theme";
import { WeatherInfoProps } from "./WeatherInfo";

export const WeatherInfoStyled = styled.div<WeatherInfoProps>`
  color: ${COLORS.textDark};
  h2 {
    color: ${COLORS.textDark};
    text-transform: none;
    font-size: ${FONTS.xs};
    font-weight: bold;
    margin: 0;
  }
  h4 {
    font-size: ${FONTS.xxs};
    font-weight: 300;
    margin: 0;
    font-style: italic;
  }
  .imgWrapper {
    text-align: center;
  }
  .imgWrapper > img {
    margin-top: ${SPACES.xxxs};
    height: ${HEIGHTS.md};
  }
  h1 {
    text-align: center;
    font-size: ${FONTS.lg};
    font-weight: bold;
    margin: 0;
    margin-left: ${SPACES.md};
    line-height: 0.5em;
    padding: ${SPACES.xxxs} 0px ${SPACES.xxs} 0px;
  }
  h1 > span {
    font-size: ${FONTS.xs};
    font-weight: 300;
    vertical-align: super;
  }
  h3 {
    text-align: center;
    font-size: ${FONTS.xxs};
    font-weight: bold;
    font-style: italic;
    margin: 0;
    margin-top: ${SPACES.xxs};
  }
`;
