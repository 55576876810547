import { dateAPIConvertor } from "../../utilsContainer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { WeatherCardGroup } from "../../stories/UI_Components/WeatherCardGroup/WeatherCardGroup";
import { degreesToFriendlyName } from "../../utilsUI";
import { Spinner } from "../../stories/UI_Components/Spinner/Spinner";
import { Button } from "../../stories/UI_Components/Button/Button";

export interface ForecastWeatherProps {
  cityName: string;
}

interface CurrentWeatherResponseProps {
  latitude?: number;
  longitude?: number;
  name?: string;
  country?: string;
  icon?: string;
  date?: number;
  temp?: number;
  main?: string;
  description?: string;
}

interface Temp {
  day: number;
  min: number;
  max: number;
  night: number;
  eve: number;
  morn: number;
}

interface FeelsLike {
  day: number;
  night: number;
  eve: number;
  morn: number;
}

interface WeatherDaily {
  id: number;
  main: string;
  description: string;
  icon: string;
}

interface Daily {
  dt: number;
  sunrise: number;
  sunset: number;
  moonrise: number;
  moonset: number;
  moon_phase: number;
  temp: Temp;
  feels_like: FeelsLike;
  pressure: number;
  humidity: number;
  dew_point: number;
  wind_speed: number;
  wind_deg: number;
  wind_gust: number;
  weather: WeatherDaily[];
  clouds: number;
  pop: number;
  uvi: number;
}

interface FiveDayForecastWeatherResponseProps {
  [x: string]: any;
  day_1?: Daily;
  day_2?: Daily;
  day_3?: Daily;
  day_4?: Daily;
  day_5?: Daily;
}

export const ForecastWeather = (props: ForecastWeatherProps) => {
  const navigateTo = useNavigate();

  // to store current weather data from API in an object
  const [currentWeather, setCurrentWeather] =
    useState<CurrentWeatherResponseProps>({});
  // to store five day forecast weather data from API in an object
  const [forecastWeather, setForecastWeather] =
    useState<FiveDayForecastWeatherResponseProps>({});
  // loading spinner check
  const [isLoading, setIsLoading] = useState(true);
  // API error check
  const [error, setError] = useState(null);

  // Delay Timer for Loading Spinner animation to take place
  const delay = (time: number) =>
    new Promise((response) => setTimeout(response, time));

  // fetch some current weather info to get forecast weather info
  useEffect(() => {
    setError(null);
    setIsLoading(true);
    setCurrentWeather({});
    setForecastWeather({});
    //Netlify Serveless Function
    const fetchCurrentWeatherData = async () => {
      // Setting Delay
      await delay(1250);
      // Serverless Function endpoint to make actual API fetch request
      await fetch(
        `/.netlify/functions/fetch-current-weather?cityName=${props.cityName}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Part Current with Forecast - Weather - This is an HTTP error: The status is Error ${response.status}`
            );
          }
          return response;
        })
        .then((response) => response.json())
        .then((CurrentWeatherData) => {
          setCurrentWeather(CurrentWeatherData);
          setError(null);
          // setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setIsLoading(false);
          // console.log(error.message);
        });
    };
    fetchCurrentWeatherData();
  }, [props.cityName]); //dependency - API call to be made when City name changes

  // fetch five day forecast weather info
  useEffect(() => {
    if (
      currentWeather.latitude === undefined &&
      currentWeather.longitude === undefined
    ) {
      return;
    } else {
      //Netlify Serveless Function
      const fetchFiveDayForecastWeatherData = async () => {
        // Setting Delay
        await delay(1250);
        // Serverless Function endpoint to make actual API fetch request
        await fetch(
          `/.netlify/functions/fetch-five-day-forecast-weather?latitude=${currentWeather.latitude}&longitude=${currentWeather.longitude}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Forecast - Weather - This is an HTTP error: The status is Error ${response.status}`
              );
            }
            return response;
          })
          .then((response) => response.json())
          .then((FiveDayforecastWeatherData) => {
            setForecastWeather(FiveDayforecastWeatherData);
            setError(null);
          })
          .catch((error) => {
            setError(error.message);
            setIsLoading(false);
            // console.log(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      fetchFiveDayForecastWeatherData();
    }
  }, [currentWeather]); //dependency API call to be made location's currentWeather changes

  // mapping through object of five day weather data so cards will be populated
  const cards = Object.keys(forecastWeather).map((weather, index) => (
    <WeatherCardGroup
      key={index}
      weatherCards={[
        {
          weatherInfo: {
            cityName: currentWeather["name"]!,
            countryName: currentWeather["country"]!,
            weatherDate: dateAPIConvertor(forecastWeather[weather].dt),
            weatherIconUrl:
              "https://openweathermap.org/img/wn/" +
              forecastWeather[weather].weather[0].icon +
              "@2x.png",
            temp: Math.round(forecastWeather[weather].temp.day),
            tempUnit: "°C",
            description:
              forecastWeather[weather].weather[0].main +
              " - " +
              forecastWeather[weather].weather[0].description,
          },
          uvIndex: Math.round(forecastWeather[weather].uvi),
          humidity: forecastWeather[weather].humidity,
          windSpeed: Math.round(forecastWeather[weather].wind_speed),
          windDegrees: degreesToFriendlyName(forecastWeather[weather].wind_deg),
          dewPoint: Math.round(forecastWeather[weather].dew_point),
          pressure: forecastWeather[weather].pressure,
          tempMax: Math.round(forecastWeather[weather].temp.max),
          tempMin: Math.round(forecastWeather[weather].temp.min),
        },
      ]}
    />
  ));

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <div className="error">
          <p>Ooops! Something went wrong.</p>
          <p>Make sure City name is correct</p>
          <p>and try again.</p>
        </div>
      ) : (
        !isLoading &&
        !error && (
          <>
            <h2>5-Day Forecast</h2>
            {/* WeatherCardGroup Component */}
            <div className="forecastContentWrapper">{cards}</div>
            {/* Button Component Normal */}
            <div className="currentBtnWrapper">
              <Button onClick={() => navigateTo("/")} children={"Current"} />
            </div>
          </>
        )
      )}
    </>
  );
};
