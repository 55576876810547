//dt is the date variable returned by openWeather API
//using dateAPI as new variable name to pass dt value which then needs to be converted into Date type
export function dateAPIConvertor(dateAPI: number) {
  const dateConverted = new Date(dateAPI * 1000);
  return dateConverted;
}

//visibility is the distance variable returned by openWeather API
//using visibilityAPI as new variable name to pass visibility value which then needs to be converted into kilometers type from meters
export function visibilityAPIConvertor(visibilityAPI: number) {
  const visibilityConverted = visibilityAPI / 1000;
  return visibilityConverted;
}
