import styled from "@emotion/styled";
import { COLORS, SIZES, SPACES } from "../../../theme";

export const CardStyled = styled.div`
  height: 100%;
  min-width: 320px;
  max-width: 350px;
  padding: ${SPACES.xs};
  background-color: ${COLORS.card};
  border-radius: ${SIZES.md};
  box-shadow: 10px 10px 5px ${COLORS.shadow};
  text-align: center;
`;
