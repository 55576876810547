import styled from "@emotion/styled";
import { COLORS, HEIGHTS, SPACES, STEP } from "../../../theme";

export const BannerStyled = styled.div`
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: ${COLORS.banner};
  box-shadow: 0px 15px 10px ${COLORS.shadow};
  min-height: ${HEIGHTS.sm};
  margin-top: ${SPACES.xxxs};
  padding-top: ${STEP}px;
  & h1,
  h2 {
    color: ${COLORS.textDark};
  }

  /* Media Queries for various Devices Responsiveness */
  /* Media Query below 700px */
  @media all and (max-width: 700px) {
     {
      width: 100vw;
      flex-direction: column;
      align-items: center;
    }
  }
`;
