import styled from "@emotion/styled";
import { COLORS, FONTS, SPACES } from "../../../theme";
import { KeyValueCardProps } from "./KeyValueCard";

export const KeyValueCardStyled = styled.div<KeyValueCardProps>`
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: ${(props) =>
    props.orientation === "horizontal" ? "row" : "column"};
  gap: ${SPACES.xxxs};
  color: ${COLORS.textDark};
  h1 {
    font-size: ${FONTS.xxs};
    font-weight: 700;
    margin: 0;
    font-style: italic;
  }
  h2 {
    font-size: ${FONTS.xxs};
    font-weight: 400;
    margin: 0;
  }

  /* Media Queries for various Devices Responsiveness */
  /* Media Query below 700px */
  @media all and (max-width: 700px) {
     {
      flex-direction: row;
    }
  }
`;
