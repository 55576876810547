import { ButtonStyled, ButtonStyledIcon } from "./Button.styles";

export interface ButtonProps {
  btnIconUrl?: string;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

//Button with Text
export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled,
}) => (
  <ButtonStyled onClick={onClick} disabled={disabled}>
    {children}
  </ButtonStyled>
);

//Button with Icon Image
export const ButtonIcon: React.FC<ButtonProps> = ({
  btnIconUrl,
  onClick,
  disabled,
}) => (
  <ButtonStyledIcon onClick={onClick} disabled={disabled}>
    <img src={btnIconUrl} alt="" />
  </ButtonStyledIcon>
);
