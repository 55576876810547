import styled from "@emotion/styled";
import { HEIGHTS, SPACES, SIZES, STEP } from "../../../theme";

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${STEP}px;
  height: ${HEIGHTS.xs};
  padding: ${SPACES.xxxs};
  && .leftVideo {
    width: ${STEP + 70}px;
  }
  && .rightVideo {
    padding-top: ${STEP + 1}px;
    width: ${SIZES.md};
  }
  && img {
    width: ${STEP + 60}px;
  }
`;
