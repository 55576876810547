import { useNavigate } from "react-router-dom";
import { dateAPIConvertor, visibilityAPIConvertor } from "../../utilsContainer";
import { useState, useEffect } from "react";
import { Card } from "../../stories/UI_Components/Card/Card";
import { WeatherInfo } from "../../stories/UI_Components/WeatherInfo/WeatherInfo";
import { Button } from "../../stories/UI_Components/Button/Button";
import { Banner } from "../../stories/UI_Components/Banner/Banner";
import { KeyValueCard } from "../../stories/UI_Components/KeyValueCard/KeyValueCard";
import { degreesToFriendlyName } from "../../utilsUI";
import { Spinner } from "../../stories/UI_Components/Spinner/Spinner";

export interface CurrentWeatherProps {
  cityName: string;
  initialLoad: boolean;
  setInitialLoad: (active: boolean) => void;
}

interface CurrentWeatherResponseProps {
  latitude?: number;
  longitude?: number;
  name?: string;
  country?: string;
  icon?: string;
  date?: number;
  temp?: number;
  main?: string;
  description?: string;
}

interface ForecastWeatherResponseProps {
  uvIndex?: number;
  humidity?: number;
  windSpeed?: number;
  windDegrees?: number;
  visibility?: number;
  pressure?: number;
}

export const CurrentWeather = (props: CurrentWeatherProps) => {
  const navigateTo = useNavigate();

  // to store current weather data from API in an object
  const [currentWeather, setCurrentWeather] =
    useState<CurrentWeatherResponseProps>({});
  // to store some current weather from the forecast weather data from API in an object
  const [forecastWeather, setForecastWeather] =
    useState<ForecastWeatherResponseProps>({});
  // loading spinner check
  const [isLoading, setIsLoading] = useState(true);
  // API error check
  const [error, setError] = useState(null);
  // joining current and forecast weather data objects in an array
  const allCurrentWeather = [{ ...currentWeather, ...forecastWeather }];

  // Delay Timer for Loading Spinner animation to take place
  const delay = (time: number) =>
    new Promise((response) => setTimeout(response, time));

  // fetch some current weather info
  useEffect(() => {
    setError(null);
    setIsLoading(true);
    setCurrentWeather({});
    setForecastWeather({});
    //Netlify Serveless Function
    const fetchCurrentWeatherData = async () => {
      // Setting Delay
      await delay(1250);
      // Serverless Function endpoint to make actual API fetch request
      await fetch(
        `/.netlify/functions/fetch-current-weather?cityName=${props.cityName}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Current - Weather - This is an HTTP error: The status is Error ${response.status}`
            );
          }
          return response;
        })
        .then((response) => response.json())
        .then((CurrentWeatherData) => {
          setCurrentWeather(CurrentWeatherData);
          setError(null);
          // setIsLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setIsLoading(false);
          // console.log(error.message);
        });
    };
    fetchCurrentWeatherData();
  }, [props.cityName]); //dependency - API call to be made when City name changes

  // fetch some forecast weather info as additional details on current weather info are found in this API call
  useEffect(() => {
    if (
      currentWeather.latitude === undefined &&
      currentWeather.longitude === undefined
    ) {
      return;
    } else {
      //Netlify Serveless Function
      const fetchCurrentWForecastWeatherData = async () => {
        // Setting Delay
        await delay(1250);
        // Serverless Function endpoint to make actual API fetch request
        await fetch(
          `/.netlify/functions/fetch-current-w-forecast-weather?latitude=${currentWeather.latitude}&longitude=${currentWeather.longitude}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error(
                `Current with Part Forecast - Weather - This is an HTTP error: The status is Error ${response.status}`
              );
            }
            return response;
          })
          .then((response) => response.json())
          .then((ForecastWeatherData) => {
            setForecastWeather(ForecastWeatherData);
            setError(null);
          })
          .catch((error) => {
            setError(error.message);
            setIsLoading(false);
            // console.log(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      };
      fetchCurrentWForecastWeatherData();
    }
  }, [currentWeather]); //dependency - API call to be made when location's currentWeather changes

  // mapping through array of weather data although one card will be populated
  const card = allCurrentWeather.map((weather: any, index) => {
    return (
      <Card key={index}>
        <WeatherInfo
          cityName={weather.name}
          countryName={weather.country}
          weatherDate={dateAPIConvertor(weather.date)}
          weatherIconUrl={
            "https://openweathermap.org/img/wn/" + weather.icon + "@2x.png"
          }
          temp={Math.round(weather.temp)}
          tempUnit="°C"
          description={weather.main + " - " + weather.description}
        />

        {/* Button Component Normal */}
        <div className="forecastBtnWrapper">
          <Button
            onClick={() => navigateTo("forecast")}
            children={"Forecast"}
          />
        </div>
      </Card>
    );
  });

  // mapping through array of weather data for banner to be populated
  const banner = allCurrentWeather.map((weather: any, index) => {
    return (
      <Banner key={index}>
        <KeyValueCard
          keyName="UV Index"
          value={Math.round(weather.uvIndex) + "%"}
          orientation="vertical"
        />
        <KeyValueCard
          keyName="Humidity"
          value={weather.humidity + "%"}
          orientation="vertical"
        />
        <KeyValueCard
          keyName="Wind Speed"
          value={Math.round(weather.windSpeed) + "m/s"}
          orientation="vertical"
        />
        <KeyValueCard
          keyName="Wind Direction"
          value={degreesToFriendlyName(weather.windDegrees)}
          orientation="vertical"
        />
        <KeyValueCard
          keyName="Visibility"
          value={visibilityAPIConvertor(weather.visibility) + "km"}
          orientation="vertical"
        />
        <KeyValueCard
          keyName="Pressure"
          value={weather.pressure + "hPA"}
          orientation="vertical"
        />
      </Banner>
    );
  });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : error ? (
        <>
          <div className="error">
            <p>Ooops...something went wrong!</p>
            <p>Please make sure city name</p>
            <p>is correct and try again.</p>
          </div>
          <Button
            onClick={() => props.setInitialLoad(true)}
            children={"Back"}
          />
        </>
      ) : (
        !isLoading &&
        !error && (
          <>
            <h2>Current Conditions</h2>
            {/* Card Component */}
            {card}
            {/* Banner Component */}
            {banner}
          </>
        )
      )}
    </>
  );
};
