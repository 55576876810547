import { HeaderStyled } from "./Header.styles";

export interface HeaderProps {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
}

export const Header = (props: HeaderProps) => {
  return (
    <HeaderStyled>
      {props.leftContent}
      {props.rightContent}
    </HeaderStyled>
  );
};
