import styled from "@emotion/styled";
import { COLORS, FONTS, HEIGHTS } from "../../../theme";

export const FooterStyled = styled.footer`
  background-color: ${COLORS.footer};
  height: ${HEIGHTS.sm};
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.textGray};
  h1 {
    font-size: ${FONTS.xxxs};
    font-weight: 200;
  }
  img {
    display: none;
  }
  .leftVideo {
    display: none;
  }
`;
