import styled from "@emotion/styled";
import { COLORS, FONTS, HEIGHTS, SPACES, STEP } from "../../../theme";

//Styling for Button with Text
export const ButtonStyled = styled.button`
  background-color: ${COLORS.button};
  color: ${COLORS.textLight};
  padding: ${SPACES.xxxs} ${SPACES.xs};
  border-radius: ${STEP}px;
  font-size: ${FONTS.xxs};
  font-weight: bold;
  border: none;
  width: auto;
  text-align: center;
  cursor: pointer;
  animation: pulse 1200ms infinite;

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(101, 49, 189, 0.85);
    }
    70% {
      box-shadow: 0 0 0 12px rgba(101, 49, 189, 0);
    }
    100% {
    }
  }
`;

//Styling for Button with Icon Image
export const ButtonStyledIcon = styled.button`
  background-color: ${COLORS.button};
  color: ${COLORS.textLight};
  padding: ${SPACES.xxxs} ${SPACES.xs};
  border-radius: 0px ${STEP}px ${STEP}px 0px;
  border: none;
  height: ${HEIGHTS.xxs};
  img {
    height: 100%;
  }
  width: auto;
  text-align: center;
  cursor: pointer;
`;
