import styled from "@emotion/styled";
import { COLORS, FONTS, SPACES } from "../../../theme";

export const WeatherCardGroupStyled = styled.div`
  & .bottomGroup {
    margin-top: ${SPACES.xxs};
  }
  .bottomGroup h1 {
    color: ${COLORS.textDark};
    font-size: ${FONTS.xxxs};
    font-weight: 600;
    margin: 0;
    font-style: italic;
  }
  & .bottomGroup h2 {
    color: ${COLORS.textDark};
    font-size: ${FONTS.xxxs};
    font-weight: 300;
    margin: 0;
  }

  .sideBySide {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
`;
