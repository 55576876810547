import { WeatherInfoStyled } from "./WeatherInfo.styles";

export interface WeatherInfoProps {
  cityName: string;
  countryName: string;
  weatherDate: Date;
  weatherIconUrl: string;
  temp: number;
  tempUnit: string;
  description: string;
}

export const WeatherInfo: React.FC<WeatherInfoProps> = ({
  cityName,
  countryName,
  weatherDate,
  weatherIconUrl,
  temp,
  tempUnit,
  description,
}) => (
  <WeatherInfoStyled
    cityName={cityName}
    countryName={countryName}
    weatherDate={weatherDate}
    weatherIconUrl={weatherIconUrl}
    temp={temp}
    tempUnit={tempUnit}
    description={description}
  >
    <h2>
      {cityName}, {countryName}
    </h2>
    <h4>{weatherDate?.toDateString()}</h4>
    <div className="imgWrapper">
      <img src={weatherIconUrl} alt="" />
    </div>
    <h1>
      {temp}
      <span>{tempUnit}</span>
    </h1>
    <h3>{description}</h3>
  </WeatherInfoStyled>
);
