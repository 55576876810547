import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { COLORS, HEIGHTS, STEP } from "../../../theme";

export const spinner = keyframes`
to {transform: rotate(360deg);}
`;

export const SpinnerStyled = styled.div`
  display: inline-block;
  width: ${HEIGHTS.md};
  height: ${HEIGHTS.md};
  border: ${STEP}px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: ${COLORS.header};
  animation: ${spinner} 750ms ease-in-out infinite;
  -webkit-animation: ${spinner} 750ms ease-in-out infinite;

  /* Media Queries for various Devices Responsiveness */
  /* Media Query below 700px */
  @media all and (max-width: 700px) {
     {
      width: ${HEIGHTS.sm};
      height: ${HEIGHTS.sm};
    }
  }
`;
