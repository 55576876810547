//deg is the wind degrees variable returned by openWeather API
//using degreesAPI as new variable name to pass deg value which then needs to be converted into compass worded directions
export function degreesToFriendlyName(degrees: number) {
  const convertedDegrees = Math.floor(degrees / 22.5 + 0.5);
  const compassDirection = [
    'N',
    'NNE',
    'NE',
    'ENE',
    'E',
    'ESE',
    'SE',
    'SSE',
    'S',
    'SSW',
    'SW',
    'WSW',
    'W',
    'WNW',
    'NW',
    'NNW',
  ];
  return compassDirection[convertedDegrees % 16];
}
