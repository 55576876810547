import styled from "@emotion/styled";
import { COLORS, FONTS, HEIGHTS } from "../../../theme";

export const HeaderStyled = styled.header`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLORS.header};
  color: ${COLORS.textDark};
  min-height: ${HEIGHTS.sm};
  box-shadow: 0px 15px 10px ${COLORS.shadow};
  h1 {
    font-size: ${FONTS.xs};
    font-weight: bold;
  }
  .leftVideo,
  .rightVideo {
    display: none;
  }

  /* Media Queries for various Devices Responsiveness */
  /* Media Query below 600px */
  @media all and (max-width: 600px) {
    div {
      flex-direction: column;
      align-items: center;
      height: auto;
      text-align: center;
    }
  }
`;
