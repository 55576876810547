import { InputStyled } from "./InputBox.styles";

export interface InputBoxProps {
  placeholder: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputBox = (props: InputBoxProps) => {
  return (
    <InputStyled
      type="text"
      placeholder={props.placeholder}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
