import styled from "@emotion/styled";
import {
  borderSizes,
  COLORS,
  HEIGHTS,
  SIZES,
  SPACES,
  STEP,
} from "../../../theme";

export const InputStyled = styled.input`
  color: ${COLORS.textDark};
  font-size: ${SIZES.xxs};
  padding: ${SPACES.xxs} ${SPACES.xxs};
  border-radius: ${STEP}px 0px 0px ${STEP}px;
  border: ${borderSizes.xs} solid ${COLORS.error};
  height: ${HEIGHTS.xxs};
  :focus {
    outline: none;
    border: ${borderSizes.sm} solid ${COLORS.success};
  }
  :focus::placeholder {
    color: transparent;
  }
`;
