import { KeyValueCardStyled } from './KeyValueCard.styles';

export interface KeyValueCardProps {
  keyName: string;
  value?: string | number;
  orientation: 'horizontal' | 'vertical';
}

export const KeyValueCard: React.FC<KeyValueCardProps> = ({
  keyName,
  value,
  orientation,
}) => (
  <KeyValueCardStyled keyName={keyName} value={value} orientation={orientation}>
    <h1>{keyName}</h1>
    <h2>{value}</h2>
  </KeyValueCardStyled>
);
