import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // Commented out StrictMode to stop useEffect from making fetches twice. (A strange behaviour since React 18)
  // Did it also to minimize calls done so as to have as much API calls available with the free account.
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
